<template>
  <div>
    <!-- 头部 -->
    <div class="search">
      <el-row>
        <el-col :span="3" class="search_input">
          <el-select v-model="condition.status" placeholder="请选择状态">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <div class="search_input">
            <el-input
              v-model="condition.name"
              placeholder="请输入查找昵称"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="search_input">
            <el-button class="btn" type="primary" @click="onSearchChone"
              >重置</el-button
            >
            <el-button class="btn" type="primary" @click="onSearch"
              >搜索</el-button
            >
            <el-button class="btn" type="primary" @click="exportHandleClick"
              >导出列表</el-button
            >
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content search_input header_list">
            <span>全部线索统计</span>
            <h2>{{ mission.clueAll }}</h2>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content search_input header_list">
            <span>线索采纳统计</span>
            <h2>{{ mission.clueAdoption }}</h2>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content search_input header_list">
            <span>不采纳线索统计</span>
            <h2>{{ mission.clueRefuse }}</h2>
          </div>
        </el-col>

        <!-- <el-col :offset="12" :span="1">
          <div class="search_input">
            <el-button class="btn" icon="el-icon-plus" type="primary"
              >新增</el-button
            >
          </div>
        </el-col> -->
      </el-row>
    </div>
    <!-- 表格 -->
    <MoTable
      :cols="cols"
      :tableData="tableData"
      @handleEventButton="handleEventButton"
      :operation="operation"
      :page="page"
    />
    <!-- 弹窗  筛选条件( 未审核/已通过/驳回，姓名)  -->
    <el-dialog
      width="30%"
      title="详情"
      :visible.sync="dialogVisible"
      append-to-body
      :before-close="onClose"
    >
      <el-form label-width="80px" ref="Form" :model="Form" :rules="rulesForm">
        <el-form-item label="昵称">
          <el-input v-model="Form.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="线索内容">
          <el-input
            v-model="Form.content"
            type="textarea"
            disabled
            rows="5"
            resize="none"
          ></el-input>
        </el-form-item>

        <el-form-item label="电话号码">
          <el-input v-model="Form.phone" disabled></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="Form.address" disabled></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="Form.postType" disabled>
            <el-option
              v-for="item in postTypes"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提交时间">
          <el-input v-model="Form.createTime" disabled></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-image
            v-for="(item, index) in Form.urlsBean"
            :key="index"
            style="width: 100px; height: 100px"
            :src="item"
            :preview-src-list="Form.urlsBean"
          >
          </el-image>
        </el-form-item>
        <el-form-item label="线索类型">
          <el-select
            v-model="Form.type"
            placeholder="请选择线索类型"
            :disabled="Form.clueStatus == 2 ? false : true"
          >
            <el-option
              v-for="item in optionsType"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="积分奖励" prop="integral">
          <el-input
            v-model.number="Form.integral"
            :disabled="Form.clueStatus == 2 ? false : true"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核原因" prop="reason">
          <el-input
            :disabled="Form.clueStatus == 2 ? false : true"
            v-model="Form.reason"
            type="textarea"
            rows="5"
            resize="none"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button
          type="primary"
          :disabled="Form.clueStatus == 2 ? false : true"
          @click="onSubmit('Form', 1)"
          >通过</el-button
        >
        <el-button
          type="info"
          :disabled="Form.clueStatus == 2 ? false : true"
          @click="onSubmit('Form', 0)"
          >驳回</el-button
        >
      </span>
    </el-dialog>
    <!-- 下载 -->
    <el-dialog title="导出公益列表" :visible.sync="isExport" width="30%" :before-close="handleCloseExport">
      <el-form ref="form" :model="formExport" label-width="80px" style="text-align: left;">
        <el-form-item label="审核状态">
          <el-select v-model="formExport.clueStatus" placeholder="选择审核状态">
            <el-option label="驳回" :value="0"></el-option>
            <el-option label="通过" :value="1"></el-option>
            <el-option label="待审核" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker v-model="time" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
            start-placeholder="开始月份" end-placeholder="结束月份">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseExport">取 消</el-button>
        <el-button type="primary" @click="exportHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MoTable from "../../../components/MoTable/MoTable";
export default {
  components: {
    MoTable,
  },
  data() {
    return {
      condition: {
        status: "",
        name: "",
      },
      // 外层弹窗
      dialogVisible: false,
      cols: [
        { type: "index", label: "序号" },
        { label: "昵称", prop: "name" },
        { label: "积分奖励", prop: "integral" },
        { label: "电话号码", prop: "phone" },
        { label: "位置", prop: "address" },
        {
          label: "类型",
          prop: "postType",
          formatter: (row, column, cellValue, index) => {
            if (row.postType == 1) {
              return "社会治安及违法犯罪行为";
            } else if (row.postType == 2) {
              return "重大安全隐患问题";
            } else if (row.postType == 3) {
              return "交通、消防等现场应急事故";
            } else if (row.postType == 4) {
              return "环境卫生管理、公共设施损坏";
            } else if (row.postType == 5) {
              return "涉毒线索举报";
            } else {
              return "其他线索";
            }
          },
        },
        { label: "提交时间", prop: "createTime" },
        {
          label: "审核状态",
          prop: "clueStatus",
          formatter: (row, column, cellValue, index) => {
            if (row.clueStatus == 2) {
              return "未审核";
            } else if (row.clueStatus == 1) {
              return "已采纳";
            } else {
              return "驳回";
            }
          },
        },
      ],
      tableData: [],
      operation: {
        details: true,
      },
      Form: {},
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      options: [
        {
          value: "0",
          label: "驳回",
        },
        {
          value: "1",
          label: "已采纳",
        },
        {
          value: "2",
          label: "未审核",
        },
      ],
      optionsType: [],
      postTypes: [
        {
          value: 1,
          label: "社会治安及违法犯罪行为",
        },
        {
          value: 2,
          label: "重大安全隐患问题",
        },
        {
          value: 3,
          label: "交通、消防等现场应急事故",
        },
        {
          value: 4,
          label: "环境卫生管理、公共设施损坏",
        },
        {
          value: 5,
          label: "涉毒线索举报",
        },
        {
          value: 6,
          label: "其他线索",
        },
      ],
      mission: {},
      isExport: false,
      time: null,
      formExport: {
        startTime: null,
        endTime: null,
        clueStatus: null
      }
    };
  },
  mounted() {
    this.getList();
    this.getStatistics();
  },
  methods: {
    exportHandleClick() {
      this.isExport = true
    },
    // 导出列表
    async exportHandle() {
      if (this.time) {
        this.formExport.startTime = this.time[0]
        this.formExport.endTime = this.time[1]
      }
      let res = await this.$exl(`/clue/exportClueList`, { ...this.formExport });
      console.log(res);
      const blob = new Blob([res], {
        type: "application/vnd.ms-excel;",
      });
      const fileName = "线索.xlsx";
      const elink = document.createElement("a");
      elink.download = fileName;
      elink.style.display = "none";
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
      this.isExport = false
      this.formExport = {
        startTime: null,
        endTime: null,
        clueStatus: null
      }
    },
    handleCloseExport() {
      this.isExport = false
    },
    async getStatistics() {
      const res = await this.$get("/mission/statistics");
      console.log(res);
      if (res.flag) {
        this.mission = res.data;
      }
    },
    // 线索类型
    async getListType() {
      const res = await this.$get("/clue/clueTypeList");
      console.log(res);
      if (res.flag) {
        this.optionsType = res.data;
      }
    },
    onSearch() {
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      this.getList();
    },
    onSearchChone() {
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      this.condition.status = "";
      this.condition.name = "";
      this.getList();
    },
    handleEventButton(type, row) {
      console.log(111, type);
      if (type == "details") {
        this.getDeatails(row.id);
        this.getListType();
        this.dialogVisible = true;
      } else if (type == "current") {
        this.page.pageNum = row;
        this.getList();
      }
    },
    // 获取列表
    async getList() {
      const res = await this.$get("/clue/getClue", {
        ...this.page,
        ...this.condition,
      });
      if (res.flag) {
        this.tableData = res.data.rows;
        this.page.total = res.data.total;
      }
    },
    // 获取详情
    async getDeatails(id) {
      console.log(id);
      const res = await this.$get("/clue/getClueById", { id: id });
      console.log(res);
      if (res.flag) {
        this.Form = res.data;
      }
    },
    onSubmit(formName, id) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.Form.clueStatus = id;
          const res = await this.$put("/clue/changeClueStatus", this.Form);
          if (res.flag) {
            this.$message.success(res.message);
            this.getList();
            this.dialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisible = false;
          done();
        })
        .catch((_) => {});
    },
  },
  computed: {
    rulesForm() {
      return {
        reason: [{ required: true, message: "请输入原因", trigger: "blur" }],
        integral: [
          { required: true, message: "请输入积分", trigger: "blur" },
          { type: "number", message: "请输入数字", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
      };
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  width: 100%;
  height: 80px;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 16px;
  .search_input {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 10px 0 30px;
    .btn {
      width: 110px;
      height: 40px;
    }
  }
}
.grid-content {
  border-radius: 4px;
}
.header_list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  span {
    font-size: 14px;
    font-family: Roboto, Roboto-Regular;
    color: rgba(51, 51, 51, 0.5);
  }
  h2 {
    display: inline-block;
    font-size: 20px;
    font-family: Roboto, Roboto-Bold;
    font-weight: 700;
    color: #2196f3;
  }
  .person {
    color: #ee5252;
    font-weight: 500;
  }
  .person1 {
    color: #36d375;
    font-weight: 500;
  }
  .person2 {
    color: #ff9d43;
  }
}
.el-image {
  margin: 0 6px;
}
</style>